import { Car } from '@/store/types';

export const createFullVanName = (car: Car): string => {
  // TODO - hardcoded - send full name from BE?
  if (car.brand !== 'IVECO Daily') {
    return car.brand;
  }
  const size = car.parameters.find((parameter) => parameter.key === 'Velikost');
  const sizeStr = size ? ` ${size.value}` : '';
  return car.brand + sizeStr;
};
