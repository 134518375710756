




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Tooltip from '@/components/tooltip.vue';
import { CarsDepositInfo } from '@/models/carsDeposit';
import { CheckIcon } from 'vue-feather-icons';

/**
 * For styling of the menu use:
 * * .deposit-dropdown__title CSS class to style <div> around title elements
 * * .deposit-dropdown__dropdown CSS class to style the menu itself (its position, size...)
 *
 * Events:
 * * open - fires boolean values whether the menu is open after change
 */
@Component({
  components: { Tooltip, CheckIcon }
})
export default class VanDepositMenu extends Vue {
  @Prop() readonly deposits!: CarsDepositInfo[];
  @Prop() readonly selectedDeposit!: CarsDepositInfo;
  @Prop() readonly onSelectedDepositChange!: (deposit: CarsDepositInfo) => void;

  private open = false;

  @Watch('open')
  private onOpenChange(open: boolean) {
    this.$emit('open', open);
  }

  get totalMinVanPrice() {
    return Math.min(...this.deposits.map((deposit) => deposit.vanPriceMin));
  }

  private handleTitleClick() {
    this.open = !this.open;
  }

  private handleMouseClick(ev: MouseEvent) {
    if (this.open && !this.$el.contains(ev.target as Node | null)) {
      this.open = false;
    }
  }

  private initMouseClickHandler() {
    this.open = false;
    document.removeEventListener('click', this.handleMouseClick);
    document.addEventListener('click', this.handleMouseClick);
  }

  private destroyMouseClickHandler() {
    document.removeEventListener('click', this.handleMouseClick);
  }

  mounted() {
    this.initMouseClickHandler();
  }

  activated() {
    this.initMouseClickHandler();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }

  deactivated() {
    this.destroyMouseClickHandler();
  }
}
