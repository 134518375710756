










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons';
import { FilterOption, FilterSubValue } from '@/store/types';
import cloneDeep from 'lodash.clonedeep';

@Component({
  components: { CheckboxField, ChevronUpIcon, ChevronDownIcon }
})
export default class TreeSelect extends Vue {
  @Prop({ required: true, type: String }) readonly name!: string[];
  @Prop({ required: true, type: Array }) readonly allValues!: FilterOption[];
  @Prop({ required: true, type: Array }) value!: FilterOption[];
  @Watch('value') onValueChanged(newVal: FilterOption[]) {
    this.selected = cloneDeep(newVal);
  }
  private selected: FilterOption[] = [];
  created() {
    this.selected = cloneDeep(this.value);
  }
  public open = false;
  public isSubtypeChecked(subtype: FilterSubValue, parent: FilterOption) {
    const currentlySelectedParent = this.selected.find((v) => v.parentValue === parent.parentValue);
    return currentlySelectedParent ? this.isSubTypeInParent(subtype, currentlySelectedParent) : false;
  }
  public isPartiallyChecked(parent: FilterOption) {
    const currentlySelectedParent = this.selected.find((v) => v.parentValue === parent.parentValue);
    if (!currentlySelectedParent || currentlySelectedParent?.subValues?.length === 0) {
      return false;
    }
    return currentlySelectedParent?.subValues?.length !== parent.subValues?.length;
  }
  public isFullyChecked(parent: FilterOption) {
    const currentlySelectedParent = this.selected.find((v) => v.parentValue === parent.parentValue);
    if (currentlySelectedParent && currentlySelectedParent.subValues === undefined) {
      // if parent is selected and has no subvalues
      return true;
    }
    if (
      !currentlySelectedParent ||
      !currentlySelectedParent.subValues ||
      currentlySelectedParent?.subValues?.length === 0
    ) {
      // if parent is not selected or has no subvalues selected
      return false;
    }
    return currentlySelectedParent?.subValues?.length === parent.subValues?.length;
  }
  get title() {
    if (this.selected.length === 0) {
      return this.name;
    }
    return this.selected.map((selectedValue) => selectedValue.parentValue).join(' | ');
  }

  public handleInput(_value: FilterOption, selected: boolean) {
    this.selected = this.selected.filter((v) => v.parentValue !== _value.parentValue);
    if (selected) {
      this.selected.push(cloneDeep(_value));
    }
    this.$emit('input', this.selected);
  }

  public handleSubtypeSelection(_value: FilterSubValue, parent: FilterOption, selected: boolean) {
    const currentlySelectedParent = this.selected.find((v) => v.parentValue === parent.parentValue);

    if (!currentlySelectedParent) {
      const strippedParent = { ...parent, subValues: [] };
      this.selected.push(cloneDeep(strippedParent));
      this.handleSubtypeSelection(_value, parent, selected);
      return;
    }
    if (selected) {
      if (!currentlySelectedParent.subValues?.includes(_value)) {
        currentlySelectedParent.subValues?.push(cloneDeep(_value));
      }
    } else {
      // when unselecting, check if there are any remaining subtypes selected. If not, deselect the whole parent
      currentlySelectedParent.subValues = currentlySelectedParent?.subValues?.filter((v) => v.value !== _value.value);
      if (currentlySelectedParent.subValues?.length === 0) {
        this.selected = this.selected.filter((v) => v.parentValue !== parent.parentValue);
      }
    }

    this.$emit('input', this.selected);
  }

  public isSubTypeInParent(subTypeToCheck: FilterSubValue, parent: FilterOption) {
    for (const parentSubValue of parent.subValues || []) {
      if (JSON.stringify(parentSubValue) === JSON.stringify(subTypeToCheck)) {
        return true;
      }
    }
    return false;
  }

  private handleMouseClick(ev: MouseEvent) {
    if (this.open && !this.$el.contains(ev.target as Node | null)) {
      this.open = false;
    }
  }

  private initMouseClickHandler() {
    document.removeEventListener('click', this.handleMouseClick);
    document.addEventListener('click', this.handleMouseClick);
  }

  private destroyMouseClickHandler() {
    this.open = false;
    document.removeEventListener('click', this.handleMouseClick);
  }

  private adjustPopupPosition() {
    const POPUP_MARGIN = 16;
    const input = this.$refs.input as HTMLElement;
    const popup = this.$refs.child as HTMLElement;
    if (input && popup) {
      let popupHeight = 0;
      const rect = input.getBoundingClientRect();
      // hidden element returns 0
      // todo: move to separate function
      if (popup.style.display === 'none') {
        const currentDisplay = popup.style.display;

        popup.style.display = 'block';
        popup.style.visibility = 'hidden';
        popup.style.position = 'absolute';

        popupHeight = popup.offsetHeight;

        popup.style.display = currentDisplay;
        popup.style.visibility = '';
        popup.style.position = '';
      }
      const viewportHeight = window.innerHeight;
      popupHeight = popupHeight || popup.offsetHeight;
      if (rect.top >= popupHeight + POPUP_MARGIN / 2) {
        // There's enough space above the parent
        popup.style.top = `-${popupHeight + POPUP_MARGIN}px `;
      } else if (viewportHeight - rect.bottom >= popupHeight) {
        // There's enough space below the parent
        popup.style.top = `${input.offsetHeight}px`;
      } else {
        // Default to below if not enough space
        popup.style.top = `${input.offsetHeight}px`;
      }
    }
  }

  mounted() {
    this.initMouseClickHandler();
    window.addEventListener('scroll', this.adjustPopupPosition);
  }

  activated() {
    this.initMouseClickHandler();
  }

  updated() {
    // to register loaded options on hidden popup
    this.adjustPopupPosition();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }
  deactivated() {
    this.destroyMouseClickHandler();
  }
  beforeDestroy() {
    window.removeEventListener('scroll', this.adjustPopupPosition);
  }
}
