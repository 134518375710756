







































import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '../../store';
import { AllowedZones, Car } from '@/store/types';
import { ChevronRightIcon, Maximize2Icon, ChevronUpIcon, ChevronDownIcon, MapPinIcon } from 'vue-feather-icons';
import { createFullVanName } from '@/components/vans/utils/createFullVanName';
import { getParameterIconSrc } from '@/components/vans/utils/carParameters';
import VanDepositMenu from '@/components/vans/vanDepositMenu.vue';

@Component({
  components: { ChevronRightIcon, Maximize2Icon, ChevronUpIcon, ChevronDownIcon, VanDepositMenu, MapPinIcon }
})
export default class VanCard extends Vue {
  @Prop() readonly car!: Car;
  @Prop() readonly daysCount!: number;
  @Prop() readonly firstDateAvailable!: Date;
  @Prop() readonly isAvailable!: boolean;

  get fullVanName() {
    return createFullVanName(this.car);
  }

  get sizeString() {
    return this.car.parameters.find((parameter) => parameter.key === 'Nákladový prostor')?.value;
  }

  get seatsCount() {
    return `${this.car.parameters.find((parameter) => parameter.key === 'Míst k sezení')?.value} místné`;
  }

  get allowedToEurope() {
    return this.car.allowedZone === AllowedZones.Europe;
  }

  public sizeIcon() {
    return getParameterIconSrc('Nákladový prostor', '#23262F', 12, 12);
  }

  public seatsIcon() {
    return getParameterIconSrc('Míst k sezení', '#23262F', 12, 12);
  }

  public focusBoxFromSelf() {
    this.$emit('carSelected');
  }

  carMouseEnter() {
    store.dispatch('setMouseoverCar', this.car);
  }

  carMouseLeave() {
    store.dispatch('setMouseoverCar', null);
  }
}
