







import { Component, Vue, Prop } from 'vue-property-decorator';
import { InfoIcon } from 'vue-feather-icons';

@Component({
  components: {
    InfoIcon
  }
})
export default class Tooltip extends Vue {
  @Prop() readonly description!: string;

  private offsetHandler() {
    if (!this.$refs.tooltip) {
      return;
    }
    const tooltip = this.$refs.tooltip as Element;
    const bounds = tooltip.getBoundingClientRect();
    tooltip.classList.toggle('offset_5', false);
    tooltip.classList.toggle('offset_50', false);
    tooltip.classList.toggle('offset_95', false);
    if (bounds.left < 85) {
      tooltip.classList.toggle('offset_5', true);
    } else if (window.innerWidth - bounds.right < 85) {
      tooltip.classList.toggle('offset_95', true);
    } else {
      tooltip.classList.toggle('offset_50', true);
    }
  }

  private initMouseClickHandler() {
    window.removeEventListener('resize', this.offsetHandler);
    window.addEventListener('resize', this.offsetHandler);
  }

  private destroyMouseClickHandler() {
    window.removeEventListener('resize', this.offsetHandler);
  }

  mounted() {
    this.offsetHandler();
    this.initMouseClickHandler();
  }

  activated() {
    this.offsetHandler();
    this.initMouseClickHandler();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }

  deactivated() {
    this.destroyMouseClickHandler();
  }
}
